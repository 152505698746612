import { useNotification } from '@/composable/useNotifications';
import LocationType from '@/domain/LocationType';
import LocationTypeApiService from '@/modules/master-data/services/api/LocationTypeApiService';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default class LocationTypeService {
    private notification = useNotification();

    private store = masterDataStore.getInstance().configStore;

    private locationTypeApiService: LocationTypeApiService;

    constructor() {
        this.locationTypeApiService = new LocationTypeApiService();
    }

    public async addNewLocationType(locationType: LocationType): Promise<boolean> {
        const response = await this.locationTypeApiService.addNewLocationType(locationType);
        if (!response.success) {
            this.notification.showError(response.message);
        } else {
            const newLocationType = new LocationType({
                ...locationType,
                id: response.data,
            });
            this.store.addLocationType(newLocationType);
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.added')} ${locationType.description}`);
        }
        return response.success;
    }

    public async updateLocationType(locationType: LocationType): Promise<boolean> {
        const response = await this.locationTypeApiService.updateLocationType(locationType);
        if (!response.success) {
            this.notification.showError(response.message);
        } else {
            this.store.updateLocationType(locationType);
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.updated')} ${locationType.description}`);
        }
        return response.success;
    }

    public async deleteLocationType(locationType: LocationType): Promise<boolean> {
        const response = await this.locationTypeApiService.deleteLocationType(locationType);
        if (!response.success) {
            this.notification.showError(response.message);
        } else {
            this.store.deleteLocationType(locationType);
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.deleted')} ${locationType.description}`);
        }
        return response.success;
    }
}
