
import { computed, defineComponent, reactive } from 'vue';
import clone from 'lodash.clone';
import LocationTypeService from '@/modules/master-data/services/LocationTypeService';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import coreStore from '@/store/CoreStore';
import LocationType, { StaticLocationType } from '@/domain/LocationType';
import { TableDefinition } from '@/types';
import useValidator from '@/validation/useValidator';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import router from '@/router';
import MasterDataRouteTypes from '../../routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import Screen from '@/components/layout/Screen.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    locationType: LocationType;
    showForm: boolean;
    modalTitle: string;
    saving: boolean;
};

export default defineComponent({
    name: 'location-type-list',
    components: {
        BDropdown,
        BDropdownItem,
        BAdvancedTable,
        BButton,
        BFormCheckbox,
        TextInput,
        BModal,
        Screen,
        BSpinner,
    },
    setup() {
        const locationTypeService = new LocationTypeService();
        const { configStore } = masterDataStore.getInstance();
        const { inventoryCategoryStore } = coreStore.getInstance();

        const { validationResult: locationTypeValidationResult, validateForm: locationTypeValidateForm, clearResults: locationTypeClearResults } = useValidator<LocationType>('location-type');

        const validateForm = (locationType: LocationType) => {
            locationTypeValidateForm(locationType);
        };

        const clearResults = () => {
            locationTypeClearResults();
        };

        const state = reactive<State>({
            showForm: false,
            locationType: new LocationType(),
            modalTitle: '',
            saving: false,
        });

        const table = computed(
            (): TableDefinition<LocationType> => ({
                items: configStore.locationTypes.filter((type) => type.staticTypeIdentifier !== StaticLocationType.BuiltIn),
                key: 'locationTypeList',
                name: getTitleCaseTranslation('core.domain.locationTypes'),
                columnDefinition: [
                    {
                        key: 'description',
                        label: getTitleCaseTranslation('core.domain.description'),
                        searchable: true,
                    },
                ],
            }),
        );

        function openAdd() {
            clearResults();
            state.locationType = new LocationType();
            state.modalTitle = getTitleCaseTranslation('core.domain.newLocationType');
            state.showForm = true;
        }

        function openEdit(item: LocationType) {
            clearResults();
            // have to clone locationType object.
            // otherwise changes are saved (by ref) to the objects in the configStore even if the user doesn't `save`
            state.locationType = new LocationType(item);
            state.modalTitle = getTitleCaseTranslation('core.domain.editLocationType');
            state.showForm = true;
        }

        async function openLocationTypesHistory(item: LocationType) {
            await router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                // todo change entityType to LocationType entityType once it's supported
                params: { entityType: EntityType.LOCATION_TYPE, entityId: item.id },
            });
        }

        async function save() {
            validateForm(state.locationType);

            if (locationTypeValidationResult.isValid) {
                state.saving = true;

                if (state.locationType.id > 0) {
                    const response = await locationTypeService.updateLocationType(state.locationType);
                    if (response) {
                        state.showForm = false;
                    }
                } else {
                    const response = await locationTypeService.addNewLocationType(state.locationType);
                    if (response) {
                        state.showForm = false;
                    }
                }

                state.saving = false;
            }
        }

        return {
            state,
            table,
            openAdd,
            openEdit,
            openLocationTypesHistory,
            save,
            locationTypeValidationResult,
            getTitleCaseTranslation,
            inventoryCategoryStore,
        };
    },
});
