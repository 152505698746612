
import { defineComponent } from 'vue';
import LocationTypeList from '@/modules/master-data/views/config/LocationTypeList.vue';

export default defineComponent({
    name: 'location-type-config',
    components: { LocationTypeList },

    setup() {
        return {

        };
    },

});
